@font-face {
    font-family: helveticaneuecyr;
    font-style: normal;
    font-weight: 400;
    src:
        local("HelveticaNeueCyr"),
        url("assets/fonts/HelveticaNeueCyr-Roman.woff") format("woff");
}
@font-face {
    font-family: helveticaneuecyr;
    font-style: normal;
    font-weight: 100;
    src:
        local("HelveticaNeueCyr"),
        url("assets/fonts/helveticaneuecyr-thin.woff") format("woff");
}
@font-face {
    font-family: helveticaneuecyr;
    font-style: normal;
    font-weight: 200;
    src:
        local("HelveticaNeueCyr"),
        url("assets/fonts/HelveticaNeueCyr-UltraLight.woff") format("woff");
}
@font-face {
    font-family: helveticaneuecyr;
    font-style: normal;
    font-weight: 300;
    src:
        local("HelveticaNeueCyr"),
        url("assets/fonts/HelveticaNeueCyr-Light.woff") format("woff");
}
@font-face {
    font-family: helveticaneuecyr;
    font-style: normal;
    font-weight: 550;
    src:
        local("HelveticaNeueCyr"),
        url("assets/fonts/helveticaneuecyr-medium.woff") format("woff");
}
@font-face {
    font-family: helveticaneuecyr;
    font-style: normal;
    font-weight: 700;
    src:
        local("HelveticaNeueCyr"),
        url("assets/fonts/helveticaneuecyr-bold.woff") format("woff");
}
*,
*::before,
*::after {
    box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

ul[class],
ol[class] {
    list-style: none;
}

a:not([class]) {
    text-decoration-skip-ink: auto;
}

img {
    max-width: 100%;
    display: block;
}

article > * + * {
    margin-top: 1em;
}

input,
button,
textarea,
select {
    font: inherit;
}

@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

.no-scroll-y {
    overflow: hidden;
}
html {
    width: 100%;
    height: 100%;
    font-family: "HelveticaNeueCyr", sans-serif;
}

body {
    margin: 0;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: auto !important;
    font-family: "HelveticaNeueCyr", sans-serif;
}
h1 {
    font-family: "HelveticaNeueCyr", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 6rem;
    line-height: 7.2rem;
    color: #292c2f;
}
h2 {
    font-family: "HelveticaNeueCyr", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 4.8rem;
    line-height: 5.8rem;
    color: #292c2f;
}
h3 {
    font-family: "HelveticaNeueCyr", sans-serif;
    font-weight: 550;
    font-style: normal;
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: #292c2f;
}
h4 {
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.6rem;
    color: #292c2f;
}
button {
    font-family: "HelveticaNeueCyr", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2rem;
    line-height: 2rem;
    color: #292c2f;
}
p {
    font-family: "HelveticaNeueCyr", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #292c2f;
}
a {
    font-family: "HelveticaNeueCyr", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #292c2f;
    text-decoration: none;
    transition: 0.6s ease;
}
@media (max-width: 768px) {
    h1 {
        font-size: 4.4rem;
        line-height: 5.3rem;
    }
    h2 {
        font-size: 3.2rem;
        line-height: 3.8rem;
    }
    h3 {
        font-size: 2.4rem;
        line-height: 2.2rem;
    }
    h4 {
        font-size: 1.6rem;
        line-height: 2.1rem;
    }
    button {
        font-size: 1.8rem;
        line-height: 1.8rem;
    }
    p {
        font-size: 1.4rem;
        line-height: 2.1rem;
    }
    a {
        font-size: 1.4rem;
        line-height: 2.1rem;
    }
}
@media (max-width: 480px) {
    h1 {
        font-size: 3.2rem;
        line-height: 3.8rem;
    }
    h2 {
        font-size: 3.2rem;
        line-height: 2.9rem;
    }
    h3 {
        font-size: 2rem;
        line-height: 2.2rem;
    }
    h4 {
        font-size: 1.6rem;
        line-height: 2.1rem;
    }
    button {
        font-size: 1.6rem;
        line-height: 1.6rem;
    }
    p {
        font-size: 1.4rem;
        line-height: 2.1rem;
    }
    a {
        font-size: 1.4rem;
        line-height: 2.1rem;
    }
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    width: auto !important;
}

.swiper-pagination-bullet {
    opacity: 0.8 !important;
}

.calculator-slider-pagination {
    font-size: 2.4rem;
    line-height: 2.9rem;
}
@media (max-width: 768px) {
    .calculator-slider-pagination {
        font-size: 1.8rem;
        line-height: 2.2rem;
    }
}

.custom-radio {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin: 0.3rem;
    min-width: 1.4rem;
    min-height: 1.4rem;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
    position: relative;
}

.custom-radio input {
    position: absolute;
    opacity: 0;
}

.custom-radio input:checked + .checkmark {
    background-color: #ff7100;
    border-color: #ff7100;
}

.checkmark {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: white;
    border: 0.1rem solid #292c2f;
}

.custom-radio input:not(:checked) + .checkmark {
    background-color: #fff;
}
.custom-radio input {
    display: none;
}
.custom-radio-label {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.4rem;
}
.custom-radio-label p {
    font-size: 2rem;
    line-height: 2.6rem;
}
@media (max-width: 768px) {
    .custom-radio-label p {
        font-size: 1.8rem;
        line-height: 2.4rem;
    }
}
@media (max-width: 480px) {
    .custom-radio-label p {
        font-size: 1.6rem;
        line-height: 2.1rem;
    }
}
