.team-slider-button-prev {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 2;
}
.team-slider-button-next {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 2;
}

.team-slider-button-prev svg path {
    transition: 0.6s ease;
}
.team-slider-button-next svg path {
    transition: 0.6s ease;
}
.team-slider-button-prev:hover svg path {
    fill: #c70b6f;
    transition: 0.6s ease;
}
.team-slider-button-prev.swiper-button-disabled:hover svg path {
    cursor: default;
    fill: #666b6e;
}
.team-slider-button-next.swiper-button-disabled:hover svg path {
    cursor: default;
    fill: #666b6e;
}
@media (max-width: 768px) {
    .team-slider-button-prev:hover svg path {
        fill: #ff7100;
        transition: 0.6s ease;
    }
}
.team-slider-button-next:hover svg path {
    fill: #c70b6f;
    transition: 0.6s ease;
}
@media (max-width: 768px) {
    .team-slider-button-next:hover svg path {
        fill: #ff7100;
        transition: 0.6s ease;
    }
}
