.header {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.4);
    z-index: 999;
}
@media (max-width: 768px) {
    .header {
        height: 7.7rem;
    }
}
@media (max-width: 480px) {
    .header {
        height: 6.4rem;
    }
}
