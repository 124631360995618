.mission-section-slider-button-prev {
    position: absolute;
    top: 50%;
    left: 0;
}
.mission-section-slider-button-next {
    position: absolute;
    top: 50%;
    right: 0;
}
.mission-section-slider-button-prev svg path {
    transition: 0.6s ease;
}
.mission-section-slider-button-next svg path {
    transition: 0.6s ease;
}
.mission-section-slider-button-prev:hover svg path {
    fill: #c70b6f;
    transition: 0.6s ease;
}
@media (max-width: 768px) {
    .mission-section-slider-button-prev:hover svg path {
        fill: #ff7100;
        transition: 0.6s ease;
    }
}
.mission-section-slider-button-next:hover svg path {
    fill: #c70b6f;
    transition: 0.6s ease;
}
@media (max-width: 768px) {
    .mission-section-slider-button-next:hover svg path {
        fill: #ff7100;
        transition: 0.6s ease;
    }
}
.mission-section-slider-button-prev,
.mission-section-slider-button-next {
    visibility: hidden;
    opacity: 0;
    transition: 0.6s ease;
    z-index: 10;
    cursor: pointer;
}

@media (max-width: 768px) {
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
        left: 50%;
        transform: translateX(-50%);
    }
}

.mission-section-slider-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    max-width: 132rem;
    position: absolute;
    left: 56%;
    z-index: 10;
    bottom: 0;
}
@media (max-width: 768px) {
    .mission-section-slider-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
        max-width: 64.8rem;
        left: 7%;
        bottom: 0;
    }
}
@media (max-width: 480px) {
    .mission-section-slider-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
        max-width: 28.8rem;
        left: 16%;
        bottom: 1rem;
    }
}
.mission-section-slider-pagination .swiper-pagination-bullet {
    margin-right: 1.2rem;
    transition: 0.6s ease;
}
.mission-section-slider-pagination .swiper-pagination-bullet:hover {
    transition: 0.6s ease;
    background-color: #c70b6f;
}
@media (max-width: 768px) {
    .mission-section-slider-pagination .swiper-pagination-bullet:hover {
        background-color: #ff7100;
        transition: 0.6s ease;
    }
}
